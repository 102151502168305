<template>
  <div class="container">
    <!-- 头部 -->
    <p class="title">
      外部源查询
      <el-tooltip
        class="item"
        effect="light"
        content="支持同时在多个外部网站进行查询，请至少选择一个查询外部源，搜索关键字不能少于两个中文字符"
        placement="top-start"
      >
        <i class="el-icon-question" style="corlor: '#f5f5f5'"></i>
      </el-tooltip>
    </p>
    <el-input
      class="search-input"
      placeholder="请输入关键字"
      v-model.trim="input"
      @keyup.enter.native="search"
    >
      <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
    </el-input>
    <!-- 复选框 -->
    <p class="select-data">选择数据源</p>
    <div class="checkbox">
      <el-checkbox
        class="all-check"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >
        全选
      </el-checkbox>
      <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item" v-for="item in checkList" :key="item.val">
          {{ item.label }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { SPECIAL_CHAR } from '@/utils/constant';
const list = [
  {
    label: '百度百科',
    url: 'https://baike.baidu.com/search?pn=0&rn=0&enc=utf8&word=',
    describe: '对关键字进行“全站搜索”类型查询',
  },
  {
    label: '企查查',
    url: 'https://www.qcc.com/web/search?key=',
    describe: '对关键字进行“企业”类型查询',
  },
  {
    label: '信用中国',
    url:
      'https://www.creditchina.gov.cn/xinyongxinxi/index.html?index=0&scenes=defaultScenario&tableName=credit_xyzx_tyshxydm&searchState=2&entityType=1,2,4,5,6,7,8&keyword=',
    describe: '对关键字进行“信用信息”类型查询',
  },
  {
    label: '好大夫',
    url: 'https://so.haodf.com/index/search?type=&kw=',
    describe: '对关键字进行所有类型查询',
  },
  {
    label: '丁香医生',
    url: 'https://dxy.com/search/result?query=',
    describe: '对关键字进行所有类型查询',
  },
  {
    label: '微医',
    url: 'https://www.guahao.com/search?searchType=search&q=',
    describe: '对关键字进行“综合”类型查询',
  },
  {
    label: '名医汇',
    url: 'http://www.mingyihui.net/vue/services/search/?keyword=',
    describe: '对关键字进行所有类型查询',
  },
];
export default {
  data() {
    return {
      // 搜索框
      input: '',
      copyInput: '',
      // 复选
      checkList: list,
      checkedList: list,
      checkAll: true,
      isIndeterminate: false,
    };
  },
  mounted() {},
  methods: {
    // 搜索框
    search() {
      let message = this.checkedList.length === 0 ? '请至少选择一个查询外部源' : '';
      message = this.input.length < 2 ? '请输入两个以上搜索关键字' : message;
      if (message) {
        this.$message({
          message,
          type: 'error',
        });
        this.showResult = false;
        return;
      }
      if (SPECIAL_CHAR.test(this.input)) {
        this.$message.error('不能输入特殊字符');
        return;
      }
      console.log(this.checkedList);
      this.checkedList.forEach(item => {
        window.open(`${item.url}${this.input}`, '_blank');
        // console.log(`${item.url}${this.input}`);
      });
    },
    // 复选
    handleCheckAllChange(val) {
      this.checkedList = val ? list : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.checkList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 22px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1d2129;
  }
  .search-input {
    margin-top: 25px;
    width: 360px;
  }
  .select-data {
    margin-top: 10px;
    color: rgb(184, 184, 184);
    font-size: 14px;
  }
  .checkbox {
    margin-top: 10px;
    display: flex;
  }
  .all-check {
    margin-right: 30px;
  }
}
</style>
